exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-en-js": () => import("./../../../src/pages/blog.en.js" /* webpackChunkName: "component---src-pages-blog-en-js" */),
  "component---src-pages-blog-pl-js": () => import("./../../../src/pages/blog.pl.js" /* webpackChunkName: "component---src-pages-blog-pl-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-pl-js": () => import("./../../../src/pages/index.pl.js" /* webpackChunkName: "component---src-pages-index-pl-js" */),
  "component---src-pages-pl-404-js": () => import("./../../../src/pages/pl/404.js" /* webpackChunkName: "component---src-pages-pl-404-js" */),
  "component---src-pages-projects-en-js": () => import("./../../../src/pages/projects.en.js" /* webpackChunkName: "component---src-pages-projects-en-js" */),
  "component---src-pages-projects-pl-js": () => import("./../../../src/pages/projects.pl.js" /* webpackChunkName: "component---src-pages-projects-pl-js" */),
  "component---src-pages-websites-en-js": () => import("./../../../src/pages/websites.en.js" /* webpackChunkName: "component---src-pages-websites-en-js" */),
  "component---src-pages-websites-pl-js": () => import("./../../../src/pages/websites.pl.js" /* webpackChunkName: "component---src-pages-websites-pl-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-website-js": () => import("./../../../src/templates/website.js" /* webpackChunkName: "component---src-templates-website-js" */)
}

